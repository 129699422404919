import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
// import { rhythm } from "../utils/typography"
import Layout from "../components/layout"
import Helmet  from "react-helmet"

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>猫背で進め</title>
        <meta name="description" content="猫背で進めは @alea12 のブログサイトです。"></meta>
        <meta property="og:title" content="猫背で進め" />
        <meta property="og:description" content="猫背で進めは @alea12 のブログサイトです。" />
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/images/ogp.png`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="猫背で進め" />
        <meta name="twitter:image" content={`${data.site.siteMetadata.siteUrl}/images/ogp.png`} />
      </Helmet>
      <div>
        <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={node.fields.slug} css={css`text-decoration: none; color: inherit;`}>
              <div css={css`color: #bbb; margin-top: 2rem;`}>
                {node.frontmatter.date} / {node.frontmatter.category}
              </div>
              <h3 class="title">
                {node.frontmatter.title}{" "}
              </h3>
              <p>{node.excerpt}</p>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            category
          }
          fields {
            slug
          }
          excerpt(truncate: true)
        }
      }
    }
  }
`